export const skills = [
    {
        id: 1,
        title: "React",
    },
    {
        id: 2,
        title: "SQL",
    },
    {
        id: 3,
        title: "Cybersecurity",
    },
    {
        id: 4,
        title: "PHP Development",
    },
    {
        id: 5,
        title: "Team Manager",
    },
    {
        id: 6,
        title: "SEO - SEA",
    },
];