import {projects} from "../components/project";
import React from "react";
import {data} from "../components/data";
import {CodeOutlined} from '@ant-design/icons';

export default function Projects(language: { language: number }) {

    return (
        <section id="projects"
                 className="text-gray-400 body-font bg-white px-10 dark:bg-gray-900 md:px-20 lg:px-40 transition-all h-screen">
            <div className="container px-5 py-10 mx-auto text-center lg:px-40">
                <div className="text-center mb-20">
                    {/*<CodeIcon className="mx-auto inline-block w-10 mb-4" />*/}
                    <CodeOutlined style={{ fontSize: '70px', marginBottom:'80px'}}/>
                    <h1 className="sm:text-4xl text-3xl  font-medium title-font mb-4 dark:text-white text-black">
                        {data["Apps I've Built"][language.language]}
                    </h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                        {data["Some of my favorite apps I've built."][language.language]}
                    </p>
                </div>
                <div className="flex flex-wrap -m-4">
                    {projects.map((project) => (
                        <a
                            href={project.link}
                            key={project.image}
                            className="sm:w-1/2 w-100 p-4">
                            <div className="flex relative">
                                <img
                                    alt="gallery"
                                    className="absolute inset-0 w-full h-full object-cover object-center"
                                    src={project.image}
                                />
                                <div
                                    className="px-8 py-10 relative z-10 w-full border-4 border-gray-800 bg-gray-900 opacity-0 hover:opacity-100">
                                    <h2 className="tracking-widest text-sm title-font font-medium text-green-400 mb-1">
                                        {project.subtitle}
                                    </h2>
                                    <h1 className="title-font text-lg font-medium text-white mb-3">
                                        {project.title}
                                    </h1>
                                    <p className="leading-relaxed">{project.description[language.language]}</p>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </section>
    );
}