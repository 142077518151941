// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onHover-dark:hover{
    color: white;
    transition-duration:400ms;
    cursor:pointer;
}

.onHover:hover{
    color: black;
    transition-duration:400ms;
    cursor: pointer;
}

.onHover{
    transition-duration:400ms;
}
.onHover-dark{
    transition-duration:400ms;
}`, "",{"version":3,"sources":["webpack://./src/views/content.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".onHover-dark:hover{\n    color: white;\n    transition-duration:400ms;\n    cursor:pointer;\n}\n\n.onHover:hover{\n    color: black;\n    transition-duration:400ms;\n    cursor: pointer;\n}\n\n.onHover{\n    transition-duration:400ms;\n}\n.onHover-dark{\n    transition-duration:400ms;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
